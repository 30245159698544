<template>
  <!-- <footer class="footer">
    {{ family_id }}


  </footer> -->
</template>

<script setup>
import { ref, onMounted  } from 'vue';
import { storeToRefs } from 'pinia';

import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const { showModal, BACK_URL, family_id } = storeToRefs(settings);

</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2em;
  color: #6c757d;
}
</style>
