<template>
  <div>
    <Modal />
    <div class="grid-table-container">
      <div class="grid-table">
        <TopBar />
        <NavBar />
        <div class="nav-bar nav-bar-dark"></div>

        <div class="grid-table-cell-wide column top-margin">
          <div>
            {{ ui_string.question_edit_title }}
          </div>
          <div v-if="caller_all" class="label-text">
            {{ settings.formatUIString(ui_string.question_parties, {
            caller_name: callee_name, callee_name:
              callee_name
          }) }}
          </div>
          <input type="text" id="question_subject" name="question_subject" class="input-field"
            :placeholder="ui_string.question_title_default" v-model="questionSubject" />
        </div>
        <div class="grid-table-cell-wide column">
          <textarea :placeholder="ui_string.question_body_default" id="question_display" name="question_display"
            ref="questionDisplayRef" rows="10" style="margin-bottom: 20px" v-model="questionText"> </textarea>
        </div>

        <AudioRecorder :questionAudioCreate="questionAudioCreate" :updateQuestion="updateQuestion"
          :questionId="questionId" />

        <div class="drop-shadow"> </div>

        <div class="grid-table-cell-narrow column">
          <div class="title-container" @click="toggleFixedSchedule">
            <span class="arrow" :class="{ 'arrow-down': showFixedSchedule }">&#9656;</span>
            <span class="centered-summary">{{ ui_string.call_sched_fixed_title }}</span>
          </div>
          <template v-if="showFixedSchedule">
            <div class="schedule-container">
              <div class="schedule-row">
                <label>Date</label>
                <input type="date" v-model="fixedDate" class="selector" @change="handleDateTimeChange('date')"
                  @focus="scrollIntoView" :min="currentDate" />
              </div>
              <div class="schedule-row">
                <label>Time</label>
                <input type="time" v-model="fixedTime" class="selector" @change="handleDateTimeChange('time')"
                  @focus="scrollIntoView" />
              </div>

              <div class="schedule-row">
                <label>Repeat</label>
                <select v-model="repeatPeriod" class="selector" :disabled="!fixedDateTime">
                  <option value="none">{{ ui_string.call_repeat_none }}</option>
                  <option value="daily">{{ ui_string.call_repeat_daily }}</option>
                  <option value="weekly">{{ ui_string.call_repeat_weekly }}</option>
                </select>
              </div>
              <div class="grid-table-cell-narrow-button column">
                <div v-if="fixedDateTime" class="schedule-row">
                  <button @click="clearFixedSchedule" class="clear-button">
                    {{ ui_string.call_sched_clear }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="drop-shadow" v-if="showFixedSchedule"> </div>
        <div class="grid-table-cell-narrow column top-margin">
          <button @click="questionSave()">{{ ui_string.quesiton_edit_save_button }}</button>
        </div>
        <div class="grid-table-cell-narrow column">
          <button @click="questionSaveDial()">{{ ui_string.call_now_button }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import AudioRecorder from "./AudioRecorder.vue";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, timezoneMap, BACK_URL, FRONT_URL, caller_id, timezone, callee_phone_number, caller_phone_number, callee_id, callee_name, ui_string, question_recording, question_recording_only, isCreatingAudio, isRecording, audioUrl, audioUrls, question_audio_id } = storeToRefs(settings);
const showModalGenerateAudioId = ref(false);
const questionId = ref("");
const questionSubject = ref("");
const questionText = ref("");
const questionPhoneMappings = ref([]);
const smsCallee = ref(0);
const repeatPeriod = ref('none');
const currentDate = new Date().toLocaleDateString('en-CA', { timeZone: timezoneMap[timezone.value] });
const fixedDate = ref("");
const fixedTime = ref("");
const showFixedSchedule = ref(false);
const questionDisplayRef = ref(null);

const fixedDateTime = computed(() => {
  if (fixedDate.value && fixedTime.value) {
    const dateTimeString = `${fixedDate.value}T${fixedTime.value}:00`;
    return dateTimeString;
  }
  return null;
});

const convertToUTC = (localDateTime) => {
  if (!localDateTime) return null;
  
  // Create a Date object in the local timezone
  const [datePart, timePart] = localDateTime.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);
  
  // Create a date in the user's timezone
  const userTimeZone = timezoneMap.value[timezone.value] || "UTC";
  const localDate = new Date();
  
  // Set date components
  localDate.setFullYear(year);
  localDate.setMonth(month - 1); // JS months are 0-indexed
  localDate.setDate(day);
  localDate.setHours(hours, minutes, 0, 0);
  
  // Format as ISO string which is in UTC
  return localDate.toISOString();
};

const handleDateTimeChange = async (type) => {
  setTimeout(async () => {
    if (fixedDate.value && fixedTime.value) {
      // Schedule will be created after the question is saved
    }
  }, 100);
};

const updateScheduleFixed = async () => {
  if (fixedDateTime.value && questionId.value) {
    try {
      const utcCallTime = convertToUTC(fixedDateTime.value, timezone.value);

      const payload = {
        question_id: questionId.value,
        callee_id: callee_id.value,
        caller_id: caller_id.value,
        call_time: utcCallTime,
        timezone: timezone.value
      };

      const response = await axios.post(`${BACK_URL.value}/schedule/schedule_fixed_create`, payload);

      if (response.data.message) {
        console.log("Fixed schedule created successfully");
      }
    } catch (error) {
      console.error("Failed to create fixed schedule:", error);
    }
  } else {
    console.warn("Invalid or missing fixedDateTime or questionId. Schedule not created.");
  }
};

watch(fixedDate, (newDate) => {
  if (!newDate) {
    fixedTime.value = "";
  }
});

watch(fixedTime, (newTime) => {
  if (!newTime) {
    fixedDate.value = "";
  }
});

const questionAudioCreate = async () => {
  isCreatingAudio.value = true;
  showModalGenerateAudioId.value = true;
  try {
    const response = await generateAudio({ text: questionText.value, caller_id: caller_id.value });
    if (response.ok) {
      const data = await response.json();
      question_audio_id.value = data.question_audio_id;

      const isAudioReady = await waitForAudioFile(data.question_audio_id);

      if (isAudioReady) {
        audioUrl.value = `${FRONT_URL.value}/question_audio/${data.question_audio_id}.mp3`;
        const audioType = question_recording.value
          ? "merged"
          : question_recording_only.value
            ? "recorded"
            : "default";

        settings.setAudioUrl(questionId.value, audioType, audioUrl.value);
        if (!question_recording.value && !question_recording_only.value) {
          settings.setAudioUrl(questionId.value, 'generated', audioUrl.value);
        }

        question_audio_id.value = data.question_audio_id;

        showModalGenerateAudioId.value = false;
        return data.question_audio_id;
      } else {
        throw new Error("Audio file not ready after multiple attempts");
      }
    } else {
      throw new Error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    showModalGenerateAudioId.value = false;
  } finally {
    isCreatingAudio.value = false;
  }
};

const generateAudio = async ({ text, caller_id }) => {
  const response = await fetch(`${BACK_URL.value}/ai/question_audio_create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: text,
      similarity_boost: 0.2,
      stability: 0.2,
      style: 0.7,
      caller_id: caller_id,
      question_recording: question_recording.value,
      question_recording_only: question_recording_only.value
    }),
  });
  return response;
};

const updateQuestion = async () => {
  if (!questionId.value) return null;
  
  try {
    const response = await axios.post(`${BACK_URL.value}/question/question_update`, null, {
      params: {
        question_id: questionId.value,
        question_subject: questionSubject.value,
        question_text: questionText.value,
        question_audio_id: question_audio_id.value,
        sms_callee: smsCallee.value,
      },
    });
    return response;
  } catch (error) {
    console.error(`Failed to update question: ${error}`);
    throw error;
  }
};

const questionSaveDial = async () => {
  try {
    await questionSave();
    if (questionId.value) {
      navToDial(questionId.value);
    }
  } catch (error) {
    console.error(`Failed to save and dial question: ${error}`);
  }
};

const questionSave = async () => {
  showModal.value = true;
  try {
    if (!questionSubject.value.trim()) {
      questionSubject.value = ui_string.value.question_subject_default;
    }

    if (!audioUrl.value) {
      const audioId = await questionAudioCreate();
      if (!audioId) {
        throw new Error("Failed to generate audio");
      }
    }

    const response = await axios.post(`${BACK_URL.value}/question/question_save`, {
      question_audio_id: question_audio_id.value,
      question_subject: questionSubject.value,
      question_text: questionText.value,
      question_callee: callee_id.value,
      question_caller: caller_id.value,
      question_nudge: "N/A",
      sms_callee: smsCallee.value,
    });

    if (response.status === 200) {
      const data = response.data;
      questionId.value = data.question_id;

      if (fixedDate.value && fixedTime.value) {
        await updateScheduleFixed();
      }

      if (repeatPeriod.value !== 'none' && fixedDateTime.value) {
        await createOrUpdateRepeatSchedule();
      }

      router.push(`/question_edit/${data.question_id}`);
    } else {
      throw new Error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Failed to save question:", error);
    alert("Failed to save question. Please try again.");
  } finally {
    showModal.value = false;
  }
};

const createOrUpdateRepeatSchedule = async () => {
  if (!fixedDateTime.value || !repeatPeriod.value || !questionId.value) {
    console.warn('Missing required data for repeat schedule');
    return;
  }

  const utcRepeatTime = convertToUTC(fixedDateTime.value, timezone.value);
  
  await axios.post(`${BACK_URL.value}/schedule/repeat_schedule_update`, {
    question_id: questionId.value,
    callee_id: callee_id.value,
    caller_id: caller_id.value,
    repeat_datetime: utcRepeatTime,
    period: repeatPeriod.value
  }, {
    withCredentials: true
  });
};

const navToDial = (id) => {
  router.push(`/dial/${id}`);
};

const scrollIntoView = (event) => {
  // setTimeout(() => {
  //   event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  // }, 100); 
};

const clearFixedSchedule = () => {
  fixedDate.value = "";
  fixedTime.value = "";
  repeatPeriod.value = 'none';
};

const toggleFixedSchedule = () => {
  showFixedSchedule.value = !showFixedSchedule.value;
};

const waitForAudioFile = async (audioId, maxAttempts = 15, interval = 1000) => {
  const audioUrl = `${FRONT_URL.value}/question_audio/${audioId}.mp3`;

  for (let i = 0; i < maxAttempts; i++) {
    try {
      const response = await fetch(audioUrl, { method: 'HEAD' });
      if (response.ok && response.headers.get('Content-Length') > 0) {
        console.log("Audio file is ready and accessible");
        return true;
      }
    } catch (error) {
      console.log(`Attempt ${i + 1}: Audio file not ready yet`);
    }
    await new Promise(resolve => setTimeout(resolve, interval));
  }

  console.error('Audio file creation or access timed out');
  return false;
};

const clearRecordingState = () => {
  question_recording.value = null;
  question_recording_only.value = false;
  if (audioUrl.value && audioUrl.value.startsWith('blob:')) {
    URL.revokeObjectURL(audioUrl.value);
  }
  audioUrl.value = null;
};

onMounted(async () => {
  await settings.fetchSettings();
  // Reset state for new question
  questionId.value = "";
  questionSubject.value = "";
  questionText.value = "";
  question_audio_id.value = "";
  audioUrl.value = "";
  questionPhoneMappings.value = [];
});

onUnmounted(() => {
  clearRecordingState();
});
</script>

<style scoped>
@import "../assets/question_edit.css";
</style> 