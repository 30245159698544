<template>
  <!-- <div class="container"> -->
    <template v-if="pendingSenders.length > 0">
      <p>Pending Instagram DMs</p>
      <div v-for="sender in pendingSenders" :key="sender.sender_id" class="sender row-no-buttons inlayed">
        <div class="message-info">
          <div>From: {{ sender.sender_username }}</div>
          <div>To: {{ sender.ig_username }}</div>
          <!-- <div>Message: {{ truncateMessage(sender.message_text) }}</div>
          <div>Source: {{ sender.source }}</div> -->
          <div class="button-group">
            <button @click="approveSender(sender)">Approve</button>
            <button @click="rejectSender(sender)">Reject</button>
          </div>
        </div>
      </div>
    </template>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, watch, defineEmits } from 'vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const { BACK_URL, family_id } = storeToRefs(settings);

const pendingSenders = ref([]);

const emit = defineEmits(['callerApproved']);

const fetchPendingSenders = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/ig/pending_dms`, {
      params: { family_id: family_id.value }
    });
    pendingSenders.value = response.data;
  } catch (error) {
    console.error('Error fetching pending senders:', error);
  }
};

const approveSender = async (sender) => {
  try {
    await axios.post(`${BACK_URL.value}/ig/approve_dm`, {
      sender_id: sender.sender_id,
      callee_id: sender.callee_id
    });
    await fetchPendingSenders();
    emit('callerApproved'); 
  } catch (error) {
    console.error('Error approving sender:', error);
  }
};

const rejectSender = async (sender) => {
  try {
    await axios.post(`${BACK_URL.value}/ig/reject_dm`, {
      sender_id: sender.sender_id
    });
    await fetchPendingSenders();
  } catch (error) {
    console.error('Error rejecting sender:', error);
  }
};

const truncateMessage = (message) => {
  if (message.length > 500) {
    return message.substring(0, 500) + '...';
  }
  return message;
};

// Watch for changes in family_id and fetch pending senders when it's not zero
watch(family_id, (newFamilyId) => {
  if (newFamilyId !== 0) {
    fetchPendingSenders();
  }
});

onMounted(() => {
  fetchPendingSenders();
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sender {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

.message-info {
  width: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

button:hover {
  background-color: #0056b3;
}
</style> 