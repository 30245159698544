<template>
  <div class="instagram-post-container">
    <div v-if="answerDetails" class="answer-details">
      <!-- <div v-if="isGenerating" class="generating-message">
        <i class="fas fa-spinner fa-spin"></i> {{ ui_string.ig_post_creating }}
      </div> -->
      <!-- <div v-if="generatedPost.image_url" class="generated-post"> -->
      <div v-if="generatedPost" class="generated-post">
        <div class="generated-image">
          <img :src="getImageUrl() + `?t=${Date.now()}`" alt="Generating image" />
        </div>
        <div class="generated-caption">
          <p>{{ generatedPost.caption }}</p>
        </div>

        <div>
          <div class="button-container">
            <button v-if="props.postDraft || isNewlyGenerated" @click="postToInstagram" :disabled="isPosting">
              <i class="fas fa-spinner fa-spin" v-if="isPosting"></i>
              {{ isPosting ? 'Posting...' : ui_string.ig_post_publish }}
            </button>
            <button @click="generatePost" :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin" v-if="isGenerating"></i>
              {{ isGenerating ? 'Rewriting...' : ui_string.ig_post_rewrite }}
            </button>
          </div>
        </div>
      </div>

      <div v-else-if="props.postDraft" class="generated-post">
        <div class="generated-image">
          <img :src="props.postDraft.image_url + `?t=${Date.now()}`" alt="Draft Instagram post image" />
        </div>
        <div class="generated-caption">
          <p>{{ props.postDraft.caption }}</p>
        </div>
        <div class="button-container">

          <button @click="postToInstagram" :disabled="isPosting">
            <i class="fas fa-spinner fa-spin" v-if="isPosting"></i>
            {{ isPosting ? 'Posting...' : ui_string.ig_post_publish }}
          </button>
          <button @click="generatePost" :disabled="isGenerating">
            <i class="fas fa-spinner fa-spin" v-if="isGenerating"></i>
            {{ isGenerating ? 'Rewriting...' : ui_string.ig_post_rewrite }}
          </button>
        </div>
      </div>

      <div v-else>
        <div class="button-container">
          
          <button @click="generatePost" :disabled="isGenerating">
            <i class="fas fa-spinner fa-spin" v-if="isGenerating"></i>
            {{ isGenerating ? 'Writing...' : ui_string.ig_post_write_another }}
          </button>
        </div>
      </div>
      
    </div>
    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>
    <div v-else class="loading">
      Loading answer details...
    </div>
    <div>
      <div class="follower-container">
            <div class="follower-grid">
              <div v-for="(caller, index) in callers" :key="caller.id" class="follower-item">
                <div class="follower-left">
                <input 
                  type="checkbox" 
                  :id="`caller-${caller.id}`" 
                  :value="caller.id" 
                  v-model="selectedCallerIds"
                />
                </div>
                <label :for="`caller-${caller.id}`" class="follower-name">{{ caller.name }}</label>
              </div>
            </div>
          </div>
          <button @click="dmFollowers" :disabled="isDmSending || !selectedCallerIds.length">
            <i class="fas fa-spinner fa-spin" v-if="isDmSending"></i>
            {{ isDmSending ? 'Sending DMs...' : `DM Selected Followers (${selectedCallerIds.length})` }}
          </button>
    </div>
    
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const props = defineProps({
  answerId: {
    type: String,
    required: true
  },
  postDraft: {
    type: Object,
    default: null
  },
  mostRecentPost: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(['postGenerated', 'scrollToTop', 'clearPostDraft']);

const settings = useSettingsStore();
const { BACK_URL, FRONT_URL, ui_string } = storeToRefs(settings);

const answerDetails = ref(null);
const error = ref(null);
const isGenerating = ref(false);
const isPosting = ref(false);
const generatedPost = ref(null);
const isNewlyGenerated = ref(false);
const isFromHistory = ref(false);
const isDmSending = ref(false);
const callers = ref([]);
const selectedCallerIds = ref([]);

const getAnswerDetails = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/answer/answer_getbyid/${props.answerId}`);
    answerDetails.value = response.data;
  } catch (err) {
    console.error('Error fetching answer details:', err);
    error.value = 'Failed to load answer details. Please try again.';
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString();
};

const generatePost = async () => {
  if (!answerDetails.value) return;

  isGenerating.value = true;
  // Clear the current post before generating new one
  generatedPost.value = null;

  // Delete existing draft if it exists
  if (props.postDraft?.id) {
    try {
      await axios.delete(`${BACK_URL.value}/ig/ig_post_draft_delete/${props.postDraft.id}`);
      emit('clearPostDraft');
    } catch (err) {
      console.error('Error deleting draft:', err);
    }
  }

  emit('scrollToTop');

  try {
    const response = await axios.post(`${BACK_URL.value}/ig/ig_post_draft_generate?answer_id=${answerDetails.value.answer_id}`, {
      answer_id: answerDetails.value.answer_id,
    });

    // Only set the new post after we get the response
    generatedPost.value = {
      caption: response.data.caption,
      image_url: response.data.image_url
    };
    isNewlyGenerated.value = true;
    emit('postGenerated');
  } catch (err) {
    console.error('Error generating post:', err);
    error.value = 'Failed to generate post. Please try again.';
    emit('postGenerated');
  } finally {
    isGenerating.value = false;
  }
};

const postToInstagram = async () => {
  if ((!generatedPost.value && !props.postDraft) || !answerDetails.value?.callee_ig_username) return;

  isPosting.value = true;
  error.value = null;

  try {
    const imageUrl = props.postDraft
      ? `${FRONT_URL.value}/ig_post/${answerDetails.value.call_sid}.png`
      : `${FRONT_URL.value}${generatedPost.value.image_url}`;

    const caption = props.postDraft
      ? props.postDraft.caption
      : generatedPost.value.caption;

    const response = await axios.post(`${BACK_URL.value}/ig/post_to_feed`, {
      username: answerDetails.value.callee_ig_username,
      caption: caption,
      image_url: imageUrl,
      answer_id: props.answerId,
      question_id: String(answerDetails.value.question_id),
      caller_id: answerDetails.value.caller_id,
      callee_id: answerDetails.value.callee_id,
      draft_id: props.postDraft?.id
    });

    if (response.data?.status === 'success') {
      alert('Successfully posted to Instagram!');
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (err) {
    console.error('Error posting to Instagram:', err);
    const errorMessage = err.response?.data?.detail ||
      err.response?.data?.message ||
      err.message ||
      'Failed to post to Instagram. Please try again.';
    error.value = errorMessage;
    alert(`Error: ${errorMessage}`);
  } finally {
    isPosting.value = false;
  }
};

const dmFollowers = async () => {
  console.log('dmFollowers called');
  console.log('answerDetails.value:', answerDetails.value);
  if (!answerDetails.value || !selectedCallerIds.value.length) return;

  isDmSending.value = true;
  error.value = null;

  try {
    const response = await axios.post(`${BACK_URL.value}/ig/dm_followers`, {
      answer_id: parseInt(props.answerId),
      caller_ids: selectedCallerIds.value
    });

    if (response.data?.status === 'success') {
      const sentCount = response.data.dm_sent_count;
      const totalCount = response.data.total_followers;
      alert(`Successfully sent DMs to ${sentCount} out of ${totalCount} followers!`);
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (err) {
    console.error('Error sending DMs to followers:', err);
    const errorMessage = err.response?.data?.detail ||
      err.response?.data?.message ||
      err.message ||
      'Failed to send DMs to followers. Please try again.';
    error.value = errorMessage;
    alert(`Error: ${errorMessage}`);
  } finally {
    isDmSending.value = false;
  }
};

const getImageUrl = () => {
  if (!generatedPost.value) return null;

  // If this is from a draft post, use the container_id path
  if (props.postDraft) {
    return `${FRONT_URL.value}/ig_post/${answerDetails.value.call_sid}.png`;
  }

  // For all other cases, use the direct image URL from OpenAI
  return generatedPost.value.image_url;
};

const fetchCallers = async () => {
  try {
    callers.value = await settings.fetchCallers();
  } catch (err) {
    console.error('Error fetching callers:', err);
  }
};

onMounted(() => {
  getAnswerDetails();
  fetchCallers();
  console.log('postDraft received:', props.postDraft);

  // If there's a post draft, use it
  if (props.postDraft) {
    generatedPost.value = {
      caption: props.postDraft.caption,
      image_url: props.postDraft.image_url
    };
    isNewlyGenerated.value = false;
    isFromHistory.value = false;
  }
  // If there's a most recent post, use it
  else if (props.mostRecentPost) {
    generatedPost.value = {
      caption: props.mostRecentPost.caption,
      image_url: `${FRONT_URL.value}/ig_post/${props.mostRecentPost.container_id}.png`
    };
    isNewlyGenerated.value = false;
    isFromHistory.value = true;
  }
});

watch(answerDetails, (newDetails) => {
  // Only set default post if we don't have a draft or most recent post
  if (!props.postDraft && !props.mostRecentPost && !generatedPost.value) {
    if (newDetails?.ig_post_list?.length > 0) {
      const mostRecentPost = newDetails.ig_post_list[0];
      generatedPost.value = {
        caption: mostRecentPost.caption,
        image_url: `${FRONT_URL.value}/ig_post/${mostRecentPost.container_id}.png`
      };
      isNewlyGenerated.value = false;
      isFromHistory.value = true;
    }
  }
}, { immediate: true });
</script>
<style scoped>
.post-actions {
  margin: 1.2em auto;
}

.follower-container {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 15px;
  border: none;
}

.follower-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 10px;
}

.follower-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.follower-left {
  width: 30px;
  flex-shrink: 0;
}

.follower-item input {
  margin: 0;
  width: auto;
}

.follower-name {
  flex: 1;
  text-align: left;
  padding-right: 30px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

button {
  width: 100%;
  /* padding: 8px 16px; */
  border-radius: 4px;
  cursor: pointer;
}

.instagram-post-container {
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* margin-top: 20px; */
}

.answer-details {
  margin-top: 20px;
  text-align: left;
  width: 100%;
  max-width: 600px;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.loading,
.generating-message {
  margin-top: 20px;
  font-style: italic;
}

.generated-post {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
}

.generated-post img {
  width: 100%;
  height: auto;
}

button:disabled {
  background-color: #b2dffc;
  cursor: not-allowed;
}
</style>